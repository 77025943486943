.App {
  height: 100vh;
  background-image: url("/assets/SilverGroup.jpg");
  background-repeat: no-repeat;
  background-position: bottom 10% right -30px;

  .welcome {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-top: 5%;
    text-align: center;
    align-items: center;
    color: #00376b;
    position: relative;

    #qrcode {
      border-radius: 25px;
    }

    .google-buttons {
      align-items: center;
      display: flex;
      flex-direction: column;

      button {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .addToGoogleWallet {
        margin-top: 10px;
      }
    }
  }

  #userDetail {
    position: absolute;
    right: 0;
    bottom: 0;
    color: rgb(214, 214, 214);
    font-size: x-small;
  }

  .btnGeneratePass 
  {
    padding: 8px;
    background-color: rgb(22, 74, 150);
    font-weight: bold;
    font-size: large;
    color: white;
    border-radius: 12px;

    &:hover {
      background-color: rgb(29, 101, 210);
      cursor: pointer;
    }
  }
  /* LOADER SPINNER */

  .lds-roller,
  .lds-roller div,
  .lds-roller div:after {
    box-sizing: border-box;
  }

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }

  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7.2px;
    height: 7.2px;
    border-radius: 50%;
    background: currentColor;
    margin: -3.6px 0 0 -3.6px;
  }

  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }

  .lds-roller div:nth-child(1):after {
    top: 62.62742px;
    left: 62.62742px;
  }

  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }

  .lds-roller div:nth-child(2):after {
    top: 67.71281px;
    left: 56px;
  }

  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }

  .lds-roller div:nth-child(3):after {
    top: 70.90963px;
    left: 48.28221px;
  }

  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }

  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }

  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }

  .lds-roller div:nth-child(5):after {
    top: 70.90963px;
    left: 31.71779px;
  }

  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }

  .lds-roller div:nth-child(6):after {
    top: 67.71281px;
    left: 24px;
  }

  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }

  .lds-roller div:nth-child(7):after {
    top: 62.62742px;
    left: 17.37258px;
  }

  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }

  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12.28719px;
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  /* LOADER SPINNER */
}

@media only screen and (max-width: 799px) and (orientation: portrait) {
  .App {
    background-size: 99%;

    .welcome {
      top: 30%;
      margin: auto;
      padding: 10px;
      align-items: center;
      #paaslogo {
        width: 80%;
      }

      h1,
      h2 {
        font-size: large;
      }
    }
  }
}
